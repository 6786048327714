export default [
  {
    path: '/:prefix(test)?/reconciliations',
    name: 'ReconciliationsListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax', 'fc'],
      // permissionsRequired: ['view_reconciliations', 'add_reconciliations'],
      title: 'Cuadratura'
    },
    component: () => import('@/modules/reconciliations/views/ReconciliationsListCreate')
  }
]
