
export default [
  {
    path: '/:prefix(test)?/metrics/gen',
    name: 'MetricsList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'MetricsList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Resumen emisión'
    },
    component: () => import('@/modules/metrics/MetricsList')
  },
  {
    path: '/:prefix(test)?/metrics/rec',
    name: 'RecMetricsList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'MetricsList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Resumen recepción'
    },
    component: () => import('@/modules/metrics/MetricsList')
  }
]